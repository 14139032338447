import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AniLink from "../../components/AniLink"

import { portfolioSchema1 as services } from "../../assets/js/helpers"

import opportunity from '../../assets/images/portfolio/sir-salon/sir-salon-opportunity.jpg';
import solution from '../../assets/images/portfolio/sir-salon/sir-salon-solution.png';
import how from '../../assets/images/portfolio/sir-salon/sir-salon-how.jpg';
import impact from '../../assets/images/portfolio/sir-salon/sir-salon-impact.jpg';
import howItWorks from '../../assets/images/portfolio/sir-salon/how-it-works.png';

function SirsalonPage() {
  return (
    <Layout>
      <SEO
        title="Portfolio - Sir Salon"
        description="A Booking App For Barbershop."
      />
      <section className="section-top-200">
        <div className="container">
          <div className="text-center">
            <div className="small-caption color-cerulean-blue">Barbershop</div>
            <div className="title color-raven-black">Sir Salon</div>
            <div className="subtitle color-raven-black">
              We work with Sir Salon to create an exceptional haircut experience, making everyone feel <b>"The Real SIR."</b>
            </div>

            <div className="landing-service section-50">
              {services.map((value, key) => {
                return (
                  <AniLink
                    to={value.link}
                    key={key}
                    className="landing-service-item"
                  >
                    <img src={value.image} alt={value.id} />
                    <div className="landing-service-item-description">
                      {value.title}
                    </div>
                  </AniLink>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="section-top-200">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={opportunity}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">OPPORTUNITY</div>
              <div className="description">
                <p>
                  Sir Salon, established in 2013, has had rapid development, from opening their first outlet to another new outlet, by now they have more than 30 outlets in Indonesia and the Philippines. With these many outlets and the demands of online orders that continue to grow, making online reservations will be challenging to do without an integrated online booking system.
                </p>
                <p>
                  Sir Salon, supported by available infrastructure (outlets in various regions) and integrated online ordering application, will make Sir Salon better at serving their new and loyal customers.
                </p>
                <p>
                  Online ordering is also a consideration for Sir Salon to be able to reduce the spread of Covid-19, where customers do not need to wait for a queue that is too long, and just come when the stylist is available.
                </p>
              </div>
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue">SOLUTION</div>
              <div className="description">
                <p className="heading color-raven-black">
                  Order Sir Salon services from your fingertips.
                </p>
                <p>
                  From making an appointment with your favourite stylist to ordering products at all Sir Salon branches, everything you can get at real gentlemen salon, you name it we do it.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image no-shadow"
                src={solution}
              />
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-5 mb-4 mb-md-0">
              <img
                className="portfolio-image"
                src={how}
              />
            </div>
            <div className="col-md-7 text-center text-md-left pl-md-5">
              <div className="subtitle color-cerulean-blue">HOW</div>
              <div className="description">
                <p>
                  As a technology partner of Sir Salon, Springkraf meets the needs of Sir Salon, from planning to implementation.
                </p>
                <p>
                  Being a partner that is always there and can be trusted, make Sir Salon more locus  on its business without bugging the technology problems.
                </p>
              </div>
            </div>
          </div>

          <div className="row section-top-150">
            <div className="col-md-7 pr-5 mb-4 mb-md-0">
              <div className="subtitle color-cerulean-blue">IMPACT</div>
              <div className="description">
                <p className="heading color-raven-black">
                  Sir Salon serves more customers without any hesitation.
                </p>
                <p>
                  Since the apps launched, thanks to the implementation of the new solution, Sir Salon can encounter significant efficiency, say goodbye to the intricate system and queues.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <img
                className="portfolio-image"
                src={impact}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="section-top-150 section-bottom-200">
        <div className="container">
          <div className="text-center">
            <div className="small-caption color-cerulean-blue">Approach</div>
            <div className="title color-raven-black">How it works</div>
          </div>
          <div className="description margin-bottom-50">
            <p>
              Step by step on how Springkraf & Sir Salon serves you :
            </p>
            <ul>
              <li>Find your favourite outlet</li>
              <li>Choose any assistance that you desire</li>
              <li>
                Choose your stylist, if you're going to random, then choose "Random Stylist"
              </li>
              <li>Select available date & time</li>
              <li>Confirm your booking</li>
            </ul>
          </div>
          <img
            src={require('../../assets/images/portfolio/sir-salon/how-it-works.png')}
            width="100%"
          />
        </div>
      </section>

      <section id="contact">
        <div className="contact">
          <div className="p-relative section-100 container text-center text-md-left">
            <div className="heading color-pine-green mb-2">
              Partner with us!
            </div>
            <div className="mb-5 color-spring-green">
              Start scaling up your business and be one of our successful
              partner!
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=6285262798297&text=Hi%2C+Springkraf%21%0AMy+name+is%3A+%0AI+want+to+talk+about%3A&source&data&lang=en"
              target="_blank"
            >
              <div className="d-inline button button-primary">Chat with us</div>
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SirsalonPage
